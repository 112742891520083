import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Actions
import podActions from '../../../actions/podActions';

// Selectors
import { getAllPods, getCityConfig } from '../../../selectors';

import styles from '../../../../stylesheets/public/components/List.module.postcss';

class PodList extends Component {
  componentDidMount() {
    this.props.fetchPodsIfNeeded();
  }

  render() {
    const { pods, cityConfig } = this.props;

    let title = "Popular Food Truck Stops";
    if (cityConfig.city_name === 'Portland') {
      title = "Popular Food Cart Pods"
    }

    return (
      <div>
        <h3>{title}</h3>
        <div className={styles.List_items}>
          {pods.map((pod) => {
            if (pod.location) {
              return (
                <div
                  className={styles.List_item}
                  key={pod.id}
                >
                  <Link to={`/schedule/${pod.location.slug}`}>
                    {pod.name} food {cityConfig.vehicle_type.toLowerCase()}s
                  </Link>
                </div>
              );
            }
          })
        }
        </div>
      </div>
    );
  }
}

PodList.propTypes = {
  pods: PropTypes.array,
  fetchPodsIfNeeded: PropTypes.func,
};

function mapStateToProps(state, props) {
  return {
    pods: getAllPods(state),
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchPodsIfNeeded() {
      // TODO fetchIfNeeded not working with router updates?
      dispatch(podActions.fetch());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PodList);
