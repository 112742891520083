import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from './CuisineLink.module.postcss';

const CuisineLink = ({ title, emoji, link }) => {

  return (
    <div className={styles.CuisineLink} >
      <Link to={link} >
        <div className={styles.CuisineLink_emoji} >{emoji}</div>
        <div>{title}</div>
      </Link>
    </div>
  );
}

CuisineLink.propTypes = {
  emoji: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default CuisineLink;
