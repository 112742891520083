import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Actions
import neighborhoodActions from '../../../actions/neighborhoodActions';

// Selectors
import { getAllNeighborhoods } from '../../../selectors';

import styles from '../../../../stylesheets/public/components/List.module.postcss';

class NeighborhoodList extends Component {
  componentDidMount() {
    this.props.fetchNeighborhoodsIfNeeded();
  }

  render() {
    const { neighborhoods, cityConfig } = this.props;

    return (
      <div>
        <h3>Popular Neighborhoods</h3>
        <div className={styles.List_items}>
          {neighborhoods.map((neighborhood) => (
            <div
              className={styles.List_item}
              key={neighborhood.id}
            >
              <Link to={`/search?day=today&location=${neighborhood.id}`}>
                {neighborhood.name} food {cityConfig.vehicle_type.toLowerCase()}s
              </Link>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

NeighborhoodList.propTypes = {
  neighborhoods: PropTypes.array,
  fetchNeighborhoodsIfNeeded: PropTypes.func,
};

function mapStateToProps(state, props) {
  return {
    neighborhoods: getAllNeighborhoods(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchNeighborhoodsIfNeeded() {
      dispatch(neighborhoodActions.fetchIfNeeded());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NeighborhoodList);
