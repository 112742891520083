import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Actions
import foodCategoryActions from '../../../actions/foodCategoryActions';

// Selectors
import { getAllFoodCategories } from '../../../selectors';

import styles from '../../../../stylesheets/public/components/List.module.postcss';

class CategoryList extends Component {
  componentDidMount() {
    this.props.fetchFoodCategoriesIfNeeded();
  }

  render() {
    const { foodCategories, cityConfig } = this.props;

    return (
      <div>
        <h3>Popular Cuisines</h3>
        <div className={styles.List_items}>
          {foodCategories.map((cat) => (
            <div
              className={styles.List_item}
              key={cat.id}
            >
              <Link to={`/search?query=${cat.id}`}>
                {cat.name} food {cityConfig.vehicle_type.toLowerCase()}s
              </Link>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

CategoryList.propTypes = {
  foodCategories: PropTypes.array,
  fetchFoodCategoriesIfNeeded: PropTypes.func,
};

function mapStateToProps(state, props) {
  return {
    foodCategories: getAllFoodCategories(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchFoodCategoriesIfNeeded() {
      dispatch(foodCategoryActions.fetchIfNeeded());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryList);
