import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Slider from 'react-slick';
import { connect } from 'react-redux';

// Components
import TruckTease from './TruckTease';

// Redux
import truckActions from '../../actions/truckActions';
import { getAllTrucks } from '../../selectors';

import '../../../stylesheets/public/components/ReactSlick.postcss';

// TODO use new loading from redux??
// TODO this looks funky when less than 4 trucks load. See AsianCatering
// TODO responsive settings breaks this on Chrome :/
class VendorsToChooseFrom extends Component {
  settings = {
    slidesToShow: 4,
    slidesToScroll: 2,
    infinite: false,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 732,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  componentDidMount() {
    const { options } = this.props;
    this.props.fetchTrucks(Object.assign({ page_size: 10 }, options));
  }

  render() {
    const { trucks, title } = this.props;

    if (trucks === null) {
      return null;
    }

    let titleText = title ? title : "500+ Vendors to Choose From";

    const slides = (trucks || []).map((truck, index) => {
      return (
        <div key={index}>
          <TruckTease truck={truck} />
        </div>
      );
    });

    return (
      <div className={"NewSection"}>
        <h2>{titleText}</h2>
        <div>
          <Slider {...this.settings}>
            {slides}
          </Slider>
        </div>
      </div>
    );
  }
}

VendorsToChooseFrom.propTypes = {
  fetchTrucks: PropTypes.func,
  trucks: PropTypes.array,
};

function mapStateToProps(state, props) {
  return {
    trucks: getAllTrucks(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchTrucks(options) {
      dispatch(truckActions.fetch(options));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorsToChooseFrom);
