import baseActionsFor from './baseActions';
import asyncActionsFor from './asyncActions';
import { podSchema, podListSchema } from './schemas';

// Create Base Actions
const baseActions = baseActionsFor('pods', 'pod');

// Create Base Async Actions
const asyncActions = asyncActionsFor({
  baseActions,
  resourceName: 'pod',
  listName: 'pods',
  resourceSchema: podSchema,
  listSchema: podListSchema
});

// Custom Actions and Base Action Overrides
const customActions = {};

// Merge Actions
const actions = Object.assign(baseActions, asyncActions, customActions);

// Export
export default actions;
