import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from './CateringCta.module.postcss';

const CateringCta = ({ vehicleType }) => {

  return (
    <div className={styles.CateringCta} >
      <img src='https://placehold.co/600x400' alt='' />
      <div className={styles.CateringCta_content} >
        <h3 className='u-mb1'>Planning an Event?</h3>
        <ul>
          <li>Free, no-obligation quotes tailored to your event</li>
          <li>Access to hundreds of top food {vehicleType.toLowerCase()}s in your area</li>
          <li>Effortless way to make your event unique and memorable</li>
        </ul>
        <Link to='/catering' className='Button'>
          Book Your Food {vehicleType} Catering
        </Link>
      </div>
      {/* TODO add sticker image here */}
    </div>
  );
}

CateringCta.propTypes = {
  vehicleType: PropTypes.string.isRequired,
};

export default CateringCta;
