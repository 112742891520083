import baseActionsFor from './baseActions';
import asyncActionsFor from './asyncActions';
import { neighborhoodSchema, neighborhoodListSchema } from './schemas';

// Create Base Actions
const baseActions = baseActionsFor('neighborhoods', 'neighborhood');

// Create Base Async Actions
const asyncActions = asyncActionsFor({
  baseActions,
  resourceName: 'neighborhood',
  listName: 'neighborhoods',
  resourceSchema: neighborhoodSchema,
  listSchema: neighborhoodListSchema
});

// Custom Actions and Base Action Overrides
const customActions = {};

// Merge Actions
const actions = Object.assign(baseActions, asyncActions, customActions);

// Export
export default actions;
