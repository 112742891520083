import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';

// Components
import Hero from '../content/Hero';
import CategoryList from '../content/CategoryList';
import PodList from '../content/PodList';
import NeighborhoodList from '../content/NeighborhoodList';
import CityList from '../content/CityList';
import LogoList from '../content/LogoList';
import Triple from '../content/Triple';
import Meta from '../../shared/Meta';
import CtaImage from '../content/CtaImage';
import VendorsToChooseFrom from '../VendorsToChooseFrom';
import CuisineLink from '../content/CuisineLink';
import CateringCta from '../content/CateringCta';

// Actions
import { clearServerRenderedPath } from '../../../actions/uiActions';

// Selectors
import { getCityConfig } from '../../../selectors';

// Misc
import IsoMorphUtils from '../../../modules/isoMorphUtils';
import styles from '../../../../stylesheets/public/layouts/AboutPage.module.postcss';
import tripleStyles from '../content/Triple.module.postcss';
import cuisineLinkStyles from '../content/CuisineLink.module.postcss';

class Home extends Component {
  componentDidMount() {
    if (IsoMorphUtils.serverRendered(this.props)) {
      this.props.clearServerRenderedPath();
    }
    this.structuredData = { home: true };
  }

  render() {
    const { cityConfig } = this.props;

    let heroImg = 'https://static.seattlefoodtruck.com/photos/homehero.webp';
    if (cityConfig.city_name === 'Portland') {
      heroImg = 'https://static.seattlefoodtruck.com/photos/portland-home.webp';
    }

    const isPortland = (cityConfig.city_name === 'Portland');
    const vehicleType = cityConfig.vehicle_type.toLowerCase();

    return (
      <div>
        <Meta
          structuredData={this.structuredData}
          preloadImg={heroImg}
        />
        <Hero photo={heroImg}>
          <h1 className='HomeHeader'>Find {cityConfig.city_name}'s Best Food {cityConfig.vehicle_type}s!</h1>
          <p>Your next meal is parked just around the corner. Search below and we'll help you find the food {vehicleType}s that are closest to you.</p>
          {/* TODO router link instead? And in the truck carousel too... */}
          <a href='/search' className='Button Button--large'>Find Food {cityConfig.vehicle_type}s</a>
        </Hero>

        <div className='NewSection'>
          <LogoList portland={isPortland} title={'Partner with companies like'} titleClassName='u-textCenter' />
        </div>

        <section className='NewSection'>
          <h2>How it works</h2>
          <div className={tripleStyles.Triple_wrapper}>
            <Triple
              image='https://placehold.co/600x400'
              title='Weddings'
              text={`Skip the boring caterer and bring a variety of food ${vehicleType}s to your big day!`}
            />
            <Triple
              image='https://placehold.co/600x400'
              title='Corporate Events'
              text={`Boost office morale and give back to your employees with an on-site food ${vehicleType}!`}
            />
            <Triple
              image='https://placehold.co/600x400'
              title='Festivals'
              text={`Food ${vehicleType}s are a great way to spice up any festival, community event or more!`}
            />
          </div>
        </section>

        {/* TODO show featured trucks here */}
        <VendorsToChooseFrom
          title={`Meet ${cityConfig.city_name}'s Best Food ${vehicleType}s`}
          // options={this.vendorsToChooseFrom}
        />

        <section className='PaddedSection--morePadding'>
          <div className='Container'>
            <h4>Food {vehicleType}s for all events!</h4>
            <div className={tripleStyles.Triple_wrapper}>
              <Triple
                image='https://static.seattlefoodtruck.com/photos/home2.jpg'
                title='Office Parks &amp; Campuses'
                link='/services/office-parks'
                text={`Boost office morale and give back to your employees with an on-site food ${vehicleType}!`}
              />
              <Triple
                image='https://static.seattlefoodtruck.com/photos/Residential.jpeg'
                title='Apartments &amp; Neighborhoods'
                link='/services/residential'
                text='Give your tenants and neighbors an upgrade from boring food delivery!'
              />
              { cityConfig.city_name !== 'Portland' &&
                <Triple
                  image='https://static.seattlefoodtruck.com/photos/Breweries.jpeg'
                  title='Breweries &amp; Taprooms'
                  link='/services/breweries'
                  text={`Bring a daily rotation of food ${vehicleType}s to your brewery and keep your customers staying longer!`}
                />
              }
              { cityConfig.city_name === 'Portland' &&
                <Triple
                  image='https://static.seattlefoodtruck.com/photos/Community_Events__HEADER.jpeg'
                  title='Community Events &amp; Festivals'
                  link='/food-truck-catering/community-events'
                  text={`From neighborhood block parties to three-day music festivals, food ${vehicleType}s are a delicious option for events of any size.`}
                />
              }

            </div>
          </div>
        </section>

        <LazyLoad height={500} offset={100} once>
          <section className='PaddedSection--morePadding'>
            <div className='Container'>
              <div className={styles.Cta}>
                <CtaImage image='https://static.seattlefoodtruck.com/photos/Wedding_HEADER.jpeg' imgClass='img-weddingHeader'/>
                <div className={styles.Cta_copy}>
                  <h4>Wedding Catering</h4>

                  <p>Looking for caterers for your big day? You’ve come to the right place! We do the hard work for you and send your request out to our growing list of {cityConfig.city_name}’s best food {vehicleType}s to help you find the perfect meal for your guests.</p>

                  <p>Our food {vehicleType}s are gourmet kitchens on wheels and can provide your wedding guests with an unbelievable experience they won't forget!</p>

                  <div>
                    <Link
                      to='/food-truck-catering/wedding-catering'
                      className='Button'
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </LazyLoad>

        <LazyLoad height={500} offset={100} once>
          <section className='PaddedSection--morePadding'>
            <div className='Container'>
              <div className={styles.Cta + ' ' + styles.Cta__reversed}>
                <CtaImage image='https://static.seattlefoodtruck.com/photos/home5.png' imgClass='img-home5'/>
                <div className={styles.Cta_copy}>
                  <h4>Office Catering</h4>

                  <p>Food {vehicleType}s are a great way to reward your team and provide an alternative to boring boxed lunch catering. Bring a food {vehicleType} to park outside your office, or have them set up inside from a true one-of-a-kind experience! Submit a request and receive quotes from dozens of {cityConfig.city_name}'s best food {vehicleType}s for your upcoming office party.</p>

                  <div>
                    <Link
                      to='/food-truck-catering/corporate-catering'
                      className='Button'
                    >
                      Book a Food {cityConfig.vehicle_type}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </LazyLoad>

        <LazyLoad height={500} offset={100} once>
          <section className='PaddedSection--morePadding'>
            <div className='Container'>
              {!isPortland &&
                <div className={styles.Cta}>
                  <CtaImage image='https://static.seattlefoodtruck.com/photos/Community_Events__HEADER.jpeg' imgClass='img-communityEvents'/>
                  <div className={styles.Cta_copy}>
                    <h4>Community Events &amp; Festivals</h4>
                    <p>Food {vehicleType}s make any event magical! Whether it's a neighborhood block party or a three-day music festival, food {vehicleType}s can provide affordable options for an event of any size. School fundraisers, store openings, and any occasion are a perfect match for {cityConfig.city_name}'s best food {vehicleType}s. Compare quotes from {vehicleType}s all over {cityConfig.city_name} in one easy place</p>
                    <div>
                      <Link
                        to='/food-truck-catering/community-events'
                        className='Button'
                      >
                        Learn More
                      </Link>
                    </div>
                  </div>
                </div>
              }

              {isPortland &&
                <div className={styles.Cta}>
                  <CtaImage image='https://static.seattlefoodtruck.com/photos/portland-about.jpeg' imgClass='img-communityEvents'/>
                  <div className={styles.Cta_copy}>
                    <h4>About FoodCartsPortland.com</h4>
                    <p>Started in 2008, FoodCartsPortland.com offers a practical guide to discover and book food carts, food trucks, food trailers and mobile caterers in Portland, Oregon. From food carts to food trucks, we're the most comprehensive guide to street food in Portland.</p>
                    <p>Help support small, locally-owned businesses by using our interactive map to find where your next meal is parked, or bring the party to you directly by booking one of Portland's best food trucks for your next event!</p>
                  </div>
                </div>
              }

            </div>
          </section>
        </LazyLoad>

        <section className='NewSection DarkSection'>
          <h2 className='u-textCenter'>Food Truck Catering For Any Event</h2>
          <div className='u-grid-row'>
            <div className='u-grid-col u-grid-md-half u-flex u-mt1'>
              <div className='DarkSection--icon'>
                {/* TODO icon */}
              </div>
              <div>
                <h3>Corporate Catering</h3>
                <p>Catered Food Truck Lunch Programs, In-Office Meal Programs, Business Park Lunches, Employee Appreciation, Client Appreciation, Holiday Parties, Milestone Celebrations, Retail Events</p>
              </div>
            </div>
            <div className='u-grid-col u-grid-md-half u-flex u-mt1'>
              <div className='DarkSection--icon'>
                {/* TODO icon */}
              </div>
              <div>
                <h3>Corporate Catering</h3>
                <p>Catered Food Truck Lunch Programs, In-Office Meal Programs, Business Park Lunches, Employee Appreciation, Client Appreciation, Holiday Parties, Milestone Celebrations, Retail Events</p>
              </div>
            </div>
          </div>
          <div className='u-grid-row'>
            <div className='u-grid-col u-grid-md-half u-flex u-mt1'>
              <div className='DarkSection--icon'>
                {/* TODO icon */}
              </div>
              <div>
                <h3>Corporate Catering</h3>
                <p>Catered Food Truck Lunch Programs, In-Office Meal Programs, Business Park Lunches, Employee Appreciation, Client Appreciation, Holiday Parties, Milestone Celebrations, Retail Events</p>
              </div>
            </div>
            <div className='u-grid-col u-grid-md-half u-flex u-mt1'>
              <div className='DarkSection--icon'>
                {/* TODO icon */}
              </div>
              <div>
                <h3>Corporate Catering</h3>
                <p>Catered Food Truck Lunch Programs, In-Office Meal Programs, Business Park Lunches, Employee Appreciation, Client Appreciation, Holiday Parties, Milestone Celebrations, Retail Events</p>
              </div>
            </div>
          </div>
        </section>

        <section className='NewSection'>
          <h3>Most Popular Cuisines</h3>
          <div className={cuisineLinkStyles.CuisineLinkContainer + ' u-flex'}>
            <CuisineLink title='Tacos' emoji='&#127790;' link='/search?query=tacos' />
            <CuisineLink title='Sandwiches' emoji='&#129386;' link='/search?query=sandwiches' />
            <CuisineLink title='Desserts' emoji='&#127846;' link='/search?query=dessert' />
            <CuisineLink title='Burgers' emoji='&#127828;' link='/search?query=burgers' />
            <CuisineLink title='BBQ' emoji='&#127830;' link='/search?query=bbq' />
            <CuisineLink title='Breakfast' emoji='&#129374;' link='/search?query=breakfast' />
            <CuisineLink title='Pizza' emoji='&#127829;' link='/search?query=pizza' />
            <CuisineLink title='Healthy' emoji='&#129367;' link='/search?query=healthy' />
            <CuisineLink title='Gyros' emoji='&#129369;' link='/search?query=gyros' />
            <CuisineLink title='Asian' emoji='&#129377;' link='/search?query=asian' />
          </div>
        </section>

        <section className='NewSection'>
          <CateringCta vehicleType={cityConfig.vehicle_type} />
        </section>

        <section className='NewSection'>
          <NeighborhoodList cityConfig={cityConfig} />
        </section>

        <section className='NewSection'>
          <CityList cityConfig={cityConfig} />
        </section>

        <section className='NewSection'>
          <CategoryList cityConfig={cityConfig} />
        </section>

        <section className='NewSection'>
          <PodList />
        </section>
      </div>
    );
  }
}

Home.propTypes = {
  ui: PropTypes.object,
  clearServerRenderedPath: PropTypes.func,
  cityConfig: PropTypes.object,
};

function mapStateToProps(state, props) {
  return {
    ui: state.ui,
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearServerRenderedPath() {
      dispatch(clearServerRenderedPath());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
